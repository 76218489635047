@media screen and (max-width: 769px) and (min-width: 767px) {

}
@media screen and (max-width: 1150px) and (min-width: 767px) {

}
@media only screen and (max-width: 1150px) {
    .hidden-md{
        display: none!important;
    }
    .search input {
        width: 100px;
    }
    .cart-pd {
        width: 220px;
    }
}
@media only screen and (max-width: 850px) {

    .responsive-menu.show,
    .responsive-menu-overlay.show {
        visibility: visible;
        opacity: 1;
    }

    .cart-pd {
        width: 280px;
    }
    .image_bg_slide img{
        max-height: 100%;
    }
    .image_bg_slide,
    .slick-track,
    .slick-track div{
        height: 100%;
    }
    .image_bg_slide{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Table_root.ui.table .tr{
        display: flex!important;
    }
    .ui.table .thead .th, .ui.table .td{
        width: 20%!important;
    }
    .body-content{
        padding-top: 86px;
    }
    .hidden-sm {
        display: none!important;
    }
    .main_slide .slick-list{
        height: 340px;
        margin-bottom: 0;
    }
    .tabs-title{
        margin-bottom: 0;
    }
    .loader-slide{
        height: 340px;
    }
    header{
        /*position: relative;*/
        padding: 10px 0;
    }
    .responsive-menu-icon{
        display: flex;
    }
    .menu{
        display: none;
    }
    /*.body-content{*/
    /*    padding-top: 0;*/
    /*}*/
    .responsive-menu {
        display: block;
    }
    .banner-info{
        line-height: 1.3;
    }
    .gallery-list{
        display: flex;
    }
    .gallery-frame{
        height: 80px;
    }
    .gallery-list {
        margin-left: -3px;
        margin-right: -3px;
    }
    .gallery-list > li {
        margin-left: 3px;
        margin-right: 3px;
    }
    .responsive-column-reverse{
        flex-direction: column-reverse!important;
    }
    .manage-my-account .custom-segment{
        min-height: 0;
        margin-bottom: 0!important;
    }
    .search{
        margin: 0 0 15px!important;
    }
    .search .ui.input{
        display: flex;
    }
    .search .ui.icon.input>input{
        padding-right: 0!important;
        flex: 1;
        border-radius: 0;
    }
    .search .button{
        text-align: center;
        padding-right: 20px!important;
        padding-left: 20px!important;
        background-color: #eea287!important;
        border-radius: 0!important;
        font-size: 20px;
    }
}
@media only screen and (max-width: 420px) {
    .visible-xs {
        display: block;
    }
    .tabs-title{
        display: none;
    }
    .body-content{
        padding-top:60px;
    }
    .header-left img{
        max-height: 35px;
    }
    .main_slide .slick-list{
        height: 170px;
        margin-bottom: 0;
    }
    .loader-slide{
        height: 165px;
    }
    .hidden-xs{
        display: none!important;
    }
    .tabs-title > div{
        margin: 0!important;
    }
    .tabs-title button{
        font-size: 20px;
    }
    .banner-section .ui.grid>[class*="three column"].row>.column{
        width: 100% !important;
        margin-bottom: 15px;
    }
    .card_image{
        height: 135px;
    }
    .tabs-title{
        margin:  0;
    }
    .tabs-list .slick-slider{
        margin-bottom: 50px;
    }
    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
        text-align: center;
    }
    .ui.table thead {
        display: none!important;
    }
    .copyright div,
    .footer-social{
        width: 100%;
        text-align: center;
    }
    .footer-social{
        margin-top: 15px;
    }
    #show-responsive-menu:focus-within ~ .manage-responsive-menu,
    .manage-responsive-menu:hover,
    #show-responsive-menu{
        display: block!important;
    }
    .manage,
    .computer-only{
        display: none!important;
    }
    .custom-grid:before{
        display: none;
    }
    .custom-grid .row .column:last-child{
        border-top: 1px solid #ccc;
        padding-top: 10px;
        margin-top: 10px;
    }
    .body-content{
        overflow-x: hidden;
    }
    .row-column-resp .column{
        margin-bottom: 10px!important;
    }
    .row-column-resp .column:last-child{
        margin-bottom: 0!important;
    }
    .horizontal_card .ui.grid>.column:not(.row){
        padding-top: 0;
        padding-bottom: 0;
    }
    .horizontal_card .card_image{
        height: 115px;
    }
    .how-to-use-row{
        flex-direction: column;
    }
    .how-to-use-row > div{
        width: 100%;
    }
    .resp-table .ui.table:not(.unstackable) tr,
    .cart .ui.table:not(.unstackable) tr{
        display: flex!important;
    }
    .cart .ui.table:not(.unstackable) tr{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .cart.checkout .ui.table:not(.unstackable) tr > td{
        width: 33.3%!important;
    }
    .cart .ui.table:not(.unstackable) tr > td:first-child,
    .cart:not(.checkout) .ui.table:not(.unstackable) tr > td:last-child{
        width: 100%!important;
    }
    .cart .ui.table:not(.unstackable) tr > td:last-child,
    .cart:not(.checkout) .ui.table:not(.unstackable) tr > td:last-child{
        padding: 0!important;
    }
    .cart-pd .header{
        text-align: left;
    }
    .show-on-mobile{
        display: block;
    }
    .remove-item-button{
        position: absolute;
        top: 20px;
        right: 0;
    }
}
