@import url(https://fonts.googleapis.com/css?family=Kanit&display=swap);
html, body {
    font-family: 'Kanit', sans-serif !important;
    min-height: 100%;
}

html, body, button, input, optgroup, select, textarea, div, span, strong,
h1, h2, h3, h4, h5, p, span, strong, .ui.button, .ui.steps .step .title {
    font-family: 'Kanit', sans-serif !important;
}

:focus {
    outline: -webkit-focus-ring-color auto 0;
}

.space {
    margin-bottom: 1rem !important;
}

header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 990;
}

.small-header {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

header.border-bottom {
    border-bottom: .1rem solid #f4f4f4;
}

.header-left, .header-center, .header-right {
    display: flex;
    align-items: center;
}

.header-left strong {
    color: #333;
    font-size: 30px
}

.footer-info h3 img,
.header-left img {
    max-height: 60px;
}

.footer-info h3 img {
    margin-top: -30px;
}

header .header-center {
    margin-right: 1rem;
}

.header-login {
    margin-left: 15px;
}

.header-login a {
    /*font-size: 16px;*/
    font-weight: bold;
    color: rgba(0, 0, 0, .87);
}

.header-login a:hover {
    color: #8c0615;
}

.header-center {
    margin-left: auto;
    margin-right: auto;
}

header .header-right {
    margin-left: 0;
}

.header-right {
    margin-left: auto;
    align-self: stretch;
}

header .container {
    position: relative;
    display: flex !important;
    align-items: center;
}

.shopping-header {
    cursor: pointer;
    transition: all 0.3s;
    color: rgba(0, 0, 0, .87);
}

.shopping-header:hover,
.shopping-header.active {
    color: #8c0615;
}

.shopping-icon-header {
    position: relative;
    display: inline-block;
    font-size: 24px;
    top: 0;
}

.shopping-icon-circle {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
    height: 20px;
    background: #8c0615;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 50%;
}

.shopping-price-header {
    font-weight: bold;
    margin-left: 0.3rem
}

.menu2 {
    display: flex;
    align-items: center;
}

.menu2, .menu2 ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu2 ul li {
    position: static;
}

header .menu2 > li > a {
    letter-spacing: .01em;
    color: #222;
    padding: 1.5rem 1rem;
    display: block;
    position: relative;
    margin-left: 5px;
    font-size: 1.1rem;
    /*font-weight: bold;*/
}

.sf-arrows a:after {
    /*content: "\f107";*/
    content: "\f0d7";
    font-family: Icons, serif;
    margin-left: 5px;
}

header .menu > li > a:before,
.tabs-title button:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: unset;
    bottom: 1rem;
    width: 100%;
    height: .1rem;
    background-color: #0f0f10;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform .3s ease;
}

header .menu > li > a:hover:before,
header .menu > li.active > a:before,
.tabs-title button.selected:before {
    transform-origin: left center;
    transform: scale(1, 1);
    transition: transform .3s ease;
}

.tabs-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;
}

.tabs-title button:before {
    bottom: 0;
}

.tabs-title button {
    border: 0;
    background: none;
    position: relative;
    color: #cccccc;
    padding: 1rem;
    padding-top: .8rem;
    padding-bottom: .8rem;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: .02em;
    text-transform: uppercase;
    cursor: pointer;
}

.slick-list div:focus,
.tabs-title button:hover,
.tabs-title button:active,
.tabs-title button:focus {
    outline: -webkit-focus-ring-color auto 0;
}

.tabs-title button.selected {
    color: #0f0f10;
    cursor: default;
}

.tabs-title > div + div {
    margin-left: 3.5rem;
}

.tabs-list {
    padding: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.tabs-list .slick-lis {
    padding: 5px 0;
}

.body-content {
    padding-top: 59px;
    padding-bottom: 30px;
    flex: 1 1;
}

.loader-slide {
    height: 700px;
    background: #f4f4f4;
}

.main_slide .slick-next {
    right: 0 !important;
}

.main_slide .slick-prev {
    left: 0 !important;
}

.main_slide .slick-prev:before,
.main_slide .slick-next:before {
    opacity: 1 !important;
    font-size: 40px !important;
}

.slick-prev:before,
.slick-next:before {
    color: #000 !important;
}

.main_slide .slick-prev,
.main_slide .slick-next {
    z-index: 1;
    width: 40px !important;
    height: 40px !important;
}

.image_bg_slide {
    background: #f6f6f6;
}

.image_bg_slide img {
    max-width: 100%;
}

.card_image .label {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
}

.card_image {
    position: relative;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.card_image img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    object-fit: cover;
}

.card-list {
    padding: 10px 15px;
}

.card {
    display: block;
    transition: all 0.3s;
    position: relative;
}

.card:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.card_fev {
    color: #ffffff;
    background: #0f0f10;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;
}

.card_label {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

.card_fev:hover {
    background: #eea287;
}

.card_fev i {
    font-size: 14px;
    margin: -2px 0 0;
}

.card_details {
    padding: 5px 5px 10px;
    line-height: 1.3;
    width: 100%;
}

.card_category_title {
    text-align: center;
    color: #ccc;
    font-size: 14px;
    transition: all 0.3s;
    cursor: pointer;
    display: block;
}

.text-left .card_pd_title,
.text-left .card_price,
.text-left .card_category_title {
    text-align: left;
}


.card_category_title:hover {
    color: #666;
}

.card_pd_title {
    cursor: pointer;
}

.card_pd_title,
.card_price {
    display: block;
    text-align: center;
    font-size: 18px;
    color: #333333;
    transition: all 0.3s;
}

.card_pd_title:hover,
.discount-price {
    color: #eea287;
}

.discount-price {
    font-weight: bold;
    margin-right: 3px;
}

.card_pd_title.brown:hover,
.card_price.brown,
.card_price.brown .discount-price {
    color: rgb(204, 153, 102);
}

.out_stock_price,
.was-price {
    color: #cccccc;
}

.was-price {
    text-decoration: line-through;
}

.card_price {
    font-size: 14px;
}

.pd-breadcrumb {
    padding: 1rem 0;
}

.pd-breadcrumb .section {
    cursor: pointer;
}

.pd-breadcrumb .section:not(.active):hover {
    color: #ccc
}

.gallery-list {
    padding: 0;
    margin: 0;
}

.gallery-list > li {
    display: block;
    margin-bottom: 10px;

}

.gallery-list > li img {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    object-fit: cover;
}

.gallery-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    overflow: hidden;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.3s;
}

.gallery-frame:hover {
    opacity: 1;
}

.selected .gallery-frame {
    border: 1px solid #bf8040;
    opacity: 1;
}

.show_image img {
    max-width: 100%;
    margin: auto;
    display: block;
}

.product-detail h2 {
    font-weight: 400;
    font-size: 26px;
    letter-spacing: -.025em;
    margin-bottom: 1.2rem;
    margin-top: -.5rem;
}

.product-detail .price {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.25;
    color: #c96;
    margin-bottom: 1.3rem;
}

.product-detail .price > div {
    margin-right: 10px;
}

.product-description {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.5;
    color: #777;
}

.qty-input input {
    border-radius: 0 !important;
    width: 60px !important;
    text-align: center !important;
}

.qty-input .button.right {
    border-radius: 0 .28571429rem .28571429rem 0 !important;
}

.pd-tabs-title li {
    display: block;
    color: #333333;
    font-size: 18px;
    /*padding: 1rem 3rem;*/
    padding: 0 15px 15px;
    transition: all 0.3s;
    border-bottom: 2px solid transparent;
    text-transform: capitalize;
    margin-right: 1rem;
    cursor: pointer;

}

.pd-tabs-title li:last-child {
    margin-right: 0;
}

.pd-tabs-title li.selected,
.pd-tabs-title li:hover {
    color: #c96;
    border-color: #d7d7d7;
    border-bottom-color: #c96;
}

.pd-tabs-title ul {
    display: flex;
    margin: 0 0 15px 0;
    /*justify-content: center;*/
    padding: 0;
}

.pd-tabs-detail {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1.8rem;
    margin-top: -0px;
    line-height: 1.7;
    /*margin-bottom: 1rem;*/
}

.cart.fixed_right {
    position: fixed;
    right: -300px;
    top: 0;
    background: #fff;
    padding: 82px 1rem 1rem;
    z-index: 100;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 100%;
    overflow: auto;
    max-width: 100%;
    transition: all 0.3s;
}

.cart-info {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cart-info .list {
    flex-grow: 1;
    overflow-y: auto;
}

.cart-footer {
    justify-self: flex-end;
}

.cart.fixed_right.active {
    right: 0;
}

.cart.fixed_right .cart-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-summary {
    display: flex;
    align-items: center;
}

.cart-summary .right.floated {
    margin-left: auto;
}

.cart-image {
    height: 50px;
    width: 70px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.cart-image img {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}

.cart-pd {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.discount-line {
    text-decoration: line-through;
    color: #9c3328;
}

.cart-total-price {
    color: #c96;
}

.cart .table {
    margin-bottom: 0 !important;
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex.flex-row > div {
    word-break: break-all;
}

.flex-description {
    padding-left: 1.85714em;
    position: relative;
}

.header-bg {
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F6F6;
}

.header-bg h1 {
    font-size: 34px;
    text-align: center;
    line-height: 1.3;
    letter-spacing: 1px;
}

.header-bg h1 span {
    font-size: 20px;
    color: #c96;
    display: block;
}

.filter-title {
    margin-bottom: 15px;
    cursor: pointer;
}

.filter-title h3 {
    margin: 0;
}

.filter-list.hide-filter-content .down,
.filter-list .left {
    display: none;
}

.filter-list .down,
.filter-list.hide-filter-content .left {
    display: block;
}

.color-frame {
    border-radius: 100%;
    padding: 3px;
    width: 30px;
    height: 30px;
    border: 1px solid transparent;
    cursor: pointer;
}

.color-frame.selected,
.color-frame:hover {
    border-color: #ccc;
}

.color-box {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #ccc;
    color: #fff;
    position: relative;
}

.color-box .icon {
    margin: 0;
    padding: 0;
}

.color-box > div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-list.no-margin .item {
    margin-left: 2px !important;
}

.input-range__label--min, .input-range__label--max {
    display: none;
}

.input-range__label--value {
    top: 100% !important;
    margin-top: 5px;
}

.input-range__track--active {
    background: #333 !important;
}

.slider-font {
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
    color: #999;
}

.input-range__slider {
    background-color: #ffffff !important;
    border-color: #999 !important;
}

.input-range {
    margin-bottom: 15px;
}

.search {
    margin-right: 1rem;
}

.cart .ui.checkbox .box:before,
.cart .ui.checkbox label:before,
.cart .ui.checkbox .box:after,
.cart .ui.checkbox label:after {
    top: 50%;
    margin-top: -10px;
}

.slick-slide img {
    margin: auto !important;
}

.custom-table tr {
    cursor: pointer;
}

.popup {
    -webkit-filter: blur(0) !important;
            filter: blur(0) !important;
}

footer {
    background-color: #222;
}

.footer-row {
    padding: 30px 0 20px;
}

.footer-info {
    color: #777;
}

footer h2,
footer h3,
.header-footer-h3 {
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3s;
}

.header-footer-h3 {
    font-size: 1.28571429rem;
    line-height: 1.28571429em;
    margin: calc(2rem - .14285714em) 0 1rem;
    font-weight: 700;
    padding: 0;
}

.footer-description {
    line-height: 1.8;
    text-align: left;
}

.footer-list {
    margin: 0;
    padding: 0;
    line-height: 1.8;
    text-align: left;
}

.footer-list li {
    display: block;
}

.footer-list li,
.footer-list li a,
.footer-social a {
    color: #777;
    transition: all 0.3s;
}

.footer-list .active,
.footer-list li:hover,
.footer-list li a:hover,
.footer-social a:hover,
.header-footer-h3:hover {
    color: #eea287;
}

.footer-list li div {
    cursor: pointer;
}

.footer-line {
    margin: 0;
    border: 0;
    border-bottom: 1px solid #444;
}

.copyright {
    color: #777;
    padding: 20px 0;
    text-align: center;
}

.footer-social a {
    margin-left: 10px;
    display: inline-block;
    font-size: 18px;
}

.view-more-button:hover {
    background: #000000;
    border-color: #000000;
    color: #fff;
}

.view-more-button.active:hover,
.view-more-button.active {
    color: #ffffff;
    border-color: #8c0615;
    background: #8c0615;
}

.view-more-button {
    letter-spacing: 2px;
    padding: 10px 20px;
    border: 2px solid #0f0f10;
    color: #0f0f10;
    background: transparent;
    transition: all 0.3s;
}

.view-more-button.icon.right i {
    margin-left: 5px
}

.banner-section {
    padding: 30px 0;
}

.banner-block {
    display: flex;
    justify-content: center;
    align-items: center;
    border: .2rem solid #ebebeb;
    background-color: transparent;
    padding-top: 3rem;
    padding-bottom: 3.2rem;
    transition: all .3s;
}

.banner-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    color: #777;
    line-height: 1.8;
    padding: 0 15px;
    text-align: center;
}

.banner-info img {
    height: 100px;
}

.banner-info i {
    font-size: 24px;
    margin: 0 0 10px;
    /*color: #000;*/
    color: #eea287;
}

.banner-info .title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.product-type-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.product-type-selected img {
    max-width: 40px;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: cover;
}

.product-type-selected a,
.product-type-selected div {
    line-height: 0.5;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid transparent;
    margin: 0 3px;
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-type-selected a.selected,
.product-type-selected a:hover,
.product-type-selected div.selected,
.product-type-selected div:hover {
    border-color: #ccc;
}

.flex-start {
    align-items: flex-start !important;
}

.options-selected {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.option-selected-thumbnail {
    line-height: 0;
    background: none;
    border: 1px solid #ececec;
    padding: 8px !important;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.option-selected-thumbnail.text {
    line-height: 1;
    min-width: 21px;
}

.option-selected-thumbnail:hover {
    border-color: #ccc;
}

.option-selected-thumbnail.selected {
    border-color: #a5673f;
    background: #a5673f;
    color: #ffffff;
}

.option-selected-thumbnail.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.options-info label {
    display: block;
    margin-bottom: 5px;
}

.custom-sub-header {
    color: #666 !important;
}

.custom-sub-header span:after {
    content: ' , ';
}

.custom-sub-header span:last-child:after {
    content: '';
}

.cart-summary .content,
.cart-summary .content > div {
    width: 100%;
}

.cart-summary .content > div input {
    width: auto !important;
}

.flex-end {
    justify-content: flex-end;
}

.custom-form h3 {
    margin-top: 0 !important;
}

.ui.checkbox label {
    cursor: pointer;
}

.icon-box {
    position: absolute;
    left: 0;
    width: 26px;
    display: inline-block;
}

.address-description {
    padding-left: 1.85714em;
}

.responsive-menu-icon {
    background: none;
    color: #000;
    border: 0;
    font-size: 20px;
    height: 100%;
    width: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    top: -4px;
    position: relative;
    display: none;
}

.responsive-menu {
    position: fixed;
    left: -300px;
    top: 0;
    bottom: 0;
    z-index: 1003;
    background-color: #333;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    box-shadow: 0.1rem 0 0.6rem 0 rgba(51, 51, 51, 0.5);
    will-change: transform;
    visibility: hidden;
    font-size: 1.2rem;
    line-height: 1.5;
    transition: all 0.4s ease;
    margin: 0;
    padding: 0;
    display: none;
}

.responsive-menu ul li,
.responsive-menu ul {
    margin: 0;
    padding: 0;
    display: block;
}

.responsive-menu ul li div,
.responsive-menu ul li a {
    position: relative;
    display: block;
    padding: 1rem 4.5rem 1rem 2rem;
    color: #fff;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.08);
}

.responsive-menu ul li.active a {
    background: #eea287;
    color: #333;
}

.responsive-menu-overlay {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(25, 25, 25, 0.25);
    z-index: 1002;
    transition: all 0.4s;
    visibility: hidden;
    opacity: 0;
}


.responsive-menu.show {
    left: 0;
}

.responsive-menu-header {
    padding: 1rem 1rem 1rem 2rem;
}

.responsive-logo {
    font-size: 30px;
    color: #eea287;
}

.responsive-logo img {
    max-width: 100%;
}

.ui.column.grid > [class*="half_five wide tablet"].column,
.ui.grid > .column.row > [class*="half_five wide tablet"].column,
.ui.grid > .row > [class*="half_five wide tablet"].column,
.ui.grid > [class*="half_five wide tablet"].column {
    width: 33.3%;
}

.manage .custom-list.ui.list a {
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s;
}

.manage .custom-list.ui.list a:hover {
    color: rgba(0, 0, 0, 1);
}

.manage .custom-list.ui.list {
    /*padding-left: 15px;*/
    margin: 0;
}

.manage .custom-list.ui.list li:before {
    margin-left: -12px !important;
}

.manage .ui.header {
    margin-bottom: 10px;
    margin-top: 10px;
}

.manage .ui.header:first-child {
    margin-top: 0 !important;
}

.custom-grid {
    position: relative;
}

.custom-grid:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 1px;
    background: #ccc;
}

.manage-my-account .sub {
    color: #999;
    letter-spacing: 1px;
}

.manage-my-account .custom-segment {
    min-height: 100%;
}

.edit-btn:before {
    content: '|';
    margin: 0 5px;
    color: #ccc;
    font-size: 10px;
    font-weight: 100 !important;
}

.edit-btn > span,
.edit-btn > a {
    color: #1e5eb6;
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 14px
}

.edit-btn > span:hover,
.edit-btn > a:hover {
    color: #0b97c4;
}

.my-account .table.ui.table.basic td,
.my-account table.ui.table.basic .td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.my-account .table.ui.table.basic tr.last-child td,
.my-account table.ui.table.basic tr.last-child .td {
    padding-bottom: 0 !important;
}

.my-account .segment {
    padding: 0;
}

.my-account .segment > .ui.header {
    padding: 1em;
}

.manage-my-account .custom-segment {
    margin-bottom: 15px !important;
}

.mobile-only,
#show-responsive-menu {
    display: none !important;
}

#show-responsive-menu {
    margin-bottom: 15px;
}

.order-details-total-price {
    font-size: 18px;
}

.ui.steps .step.active .title {
    color: #000;
}

.summary-list .flex.row {
    margin-bottom: 5px;
}

.steps {
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
}

.step {
    /* Make all steps have the same width */
    flex: 1 1;
}

.step-connection {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}

.step-number {
    align-items: center;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;

    /* Rounded border */
    /*background-color: #ffffff;*/
    /*background-color: rgba(0, 0, 0, .3);*/
    background-color: white;
    border-radius: 9999px;
    border: 2px solid #fbbd08;
    height: 15px;
    width: 15px;
}

.step-connector-left,
.step-connector-right {
    flex: 1 1;
}

.step-line {
    /*background-color: #4CAF51;*/
    background-color: rgba(0, 0, 0, 0.3);
    height: 3px;
    width: 100%;
}

.step-line.active {
    background-color: #4CAF51;
}

.step-title {
    color: #999;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 16px;
}

.step-active .step-number {
    border-color: #4CAF51;
}

.step-done .step-number,
.step-done .step-connector-left .step-line,
.step-done .step-connector-right .step-line {
    color: white;
    background-color: #4CAF51;
    border-color: #4CAF51;
}

.step-done .step-title {
    color: #4CAF51;
    font-weight: bold;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.login-bg {
    padding: 100px 0;
}

.login-bg .column .header {
    display: block;
    text-align: center;
}

.sign-in button.active {
    cursor: default;
}

.sign-in button:hover {
    cursor: pointer;
}

.privacy-policy {

}

.form-link {
    font-size: 14px;
    color: rgba(0, 0, 0, .87);
    cursor: pointer;
}

.form-link:hover {
    color: rgba(0, 0, 0, 1);
}

.ui.button {
    transition: all 0.3s;
    font-weight: 100;
}

.manage-responsive-menu {
    margin-bottom: 15px;
}

.Table_root.ui.table,
.Table_root.ui.table .tr {
    border: 0
}

.horizontal_card .card_image {
    height: 150px;
}

.category-list a {
    color: #333;
    transition: all 0.3s;
}

.category-list a:hover {
    color: #c96
}

.my-account .ui.menu.six.item .item {
    flex: 1 1;
}

.warehouse-map-iframe {
    width: 100%;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 25px;
    border: 2px solid #BEBEBE;
}

.warehouse-map-iframe iframe {
    width: 100%;
    height: 100%;
    border-style: none;
}

.payment-way {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -5px 5px;
}

.payment-way li {
    width: 25%;
    display: block;
    padding: 5px;
}

.payment-way li:last-child {
    width: 50%;
}

.payment-way li img {
    max-width: 100%;
}

.how-to-use-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.how-to-use-row > div {
    width: 33.33%;
    padding: 15px;
}

.how-to-use-row img {
    max-width: 100%;
    transition: all 0.3s;
}

.how-to-use-row > div:hover img {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.ui.divider {
    border-color: #f6f6f6;
}

.ui.grid > .ui.segment.column {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0 !important;
}

.show-on-mobile {
    display: none;
    font-weight: bold;
}

.visible-xs {
    display: none;
}

.resp_tabs_modal > div:first-child button {
    margin-top: 0;
}

.resp_tabs_modal button.active {
    background: #ccc;
}

.resp_tabs_modal button {
    margin-top: 10px !important;
}

.resp_tabs_modal button.selected {
    background: #a5673f;
    color: #fff;
}

.white-space-pre-line {
    white-space: pre-line;
}

/*.slider-image img{*/
/*    max-width:100%;*/
/*    width: auto!important;*/
/*    margin: auto!important;*/
/*}*/

.ui.popup .content {
    white-space: pre-line;
}

@media screen and (max-width: 769px) and (min-width: 767px) {

}
@media screen and (max-width: 1150px) and (min-width: 767px) {

}
@media only screen and (max-width: 1150px) {
    .hidden-md{
        display: none!important;
    }
    .search input {
        width: 100px;
    }
    .cart-pd {
        width: 220px;
    }
}
@media only screen and (max-width: 850px) {

    .responsive-menu.show,
    .responsive-menu-overlay.show {
        visibility: visible;
        opacity: 1;
    }

    .cart-pd {
        width: 280px;
    }
    .image_bg_slide img{
        max-height: 100%;
    }
    .image_bg_slide,
    .slick-track,
    .slick-track div{
        height: 100%;
    }
    .image_bg_slide{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Table_root.ui.table .tr{
        display: flex!important;
    }
    .ui.table .thead .th, .ui.table .td{
        width: 20%!important;
    }
    .body-content{
        padding-top: 86px;
    }
    .hidden-sm {
        display: none!important;
    }
    .main_slide .slick-list{
        height: 340px;
        margin-bottom: 0;
    }
    .tabs-title{
        margin-bottom: 0;
    }
    .loader-slide{
        height: 340px;
    }
    header{
        /*position: relative;*/
        padding: 10px 0;
    }
    .responsive-menu-icon{
        display: flex;
    }
    .menu{
        display: none;
    }
    /*.body-content{*/
    /*    padding-top: 0;*/
    /*}*/
    .responsive-menu {
        display: block;
    }
    .banner-info{
        line-height: 1.3;
    }
    .gallery-list{
        display: flex;
    }
    .gallery-frame{
        height: 80px;
    }
    .gallery-list {
        margin-left: -3px;
        margin-right: -3px;
    }
    .gallery-list > li {
        margin-left: 3px;
        margin-right: 3px;
    }
    .responsive-column-reverse{
        flex-direction: column-reverse!important;
    }
    .manage-my-account .custom-segment{
        min-height: 0;
        margin-bottom: 0!important;
    }
    .search{
        margin: 0 0 15px!important;
    }
    .search .ui.input{
        display: flex;
    }
    .search .ui.icon.input>input{
        padding-right: 0!important;
        flex: 1 1;
        border-radius: 0;
    }
    .search .button{
        text-align: center;
        padding-right: 20px!important;
        padding-left: 20px!important;
        background-color: #eea287!important;
        border-radius: 0!important;
        font-size: 20px;
    }
}
@media only screen and (max-width: 420px) {
    .visible-xs {
        display: block;
    }
    .tabs-title{
        display: none;
    }
    .body-content{
        padding-top:60px;
    }
    .header-left img{
        max-height: 35px;
    }
    .main_slide .slick-list{
        height: 170px;
        margin-bottom: 0;
    }
    .loader-slide{
        height: 165px;
    }
    .hidden-xs{
        display: none!important;
    }
    .tabs-title > div{
        margin: 0!important;
    }
    .tabs-title button{
        font-size: 20px;
    }
    .banner-section .ui.grid>[class*="three column"].row>.column{
        width: 100% !important;
        margin-bottom: 15px;
    }
    .card_image{
        height: 135px;
    }
    .tabs-title{
        margin:  0;
    }
    .tabs-list .slick-slider{
        margin-bottom: 50px;
    }
    .ui.table:not(.unstackable) tr>td,
    .ui.table:not(.unstackable) tr>th {
        text-align: center;
    }
    .ui.table thead {
        display: none!important;
    }
    .copyright div,
    .footer-social{
        width: 100%;
        text-align: center;
    }
    .footer-social{
        margin-top: 15px;
    }
    #show-responsive-menu:focus-within ~ .manage-responsive-menu,
    .manage-responsive-menu:hover,
    #show-responsive-menu{
        display: block!important;
    }
    .manage,
    .computer-only{
        display: none!important;
    }
    .custom-grid:before{
        display: none;
    }
    .custom-grid .row .column:last-child{
        border-top: 1px solid #ccc;
        padding-top: 10px;
        margin-top: 10px;
    }
    .body-content{
        overflow-x: hidden;
    }
    .row-column-resp .column{
        margin-bottom: 10px!important;
    }
    .row-column-resp .column:last-child{
        margin-bottom: 0!important;
    }
    .horizontal_card .ui.grid>.column:not(.row){
        padding-top: 0;
        padding-bottom: 0;
    }
    .horizontal_card .card_image{
        height: 115px;
    }
    .how-to-use-row{
        flex-direction: column;
    }
    .how-to-use-row > div{
        width: 100%;
    }
    .resp-table .ui.table:not(.unstackable) tr,
    .cart .ui.table:not(.unstackable) tr{
        display: flex!important;
    }
    .cart .ui.table:not(.unstackable) tr{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .cart.checkout .ui.table:not(.unstackable) tr > td{
        width: 33.3%!important;
    }
    .cart .ui.table:not(.unstackable) tr > td:first-child,
    .cart:not(.checkout) .ui.table:not(.unstackable) tr > td:last-child{
        width: 100%!important;
    }
    .cart .ui.table:not(.unstackable) tr > td:last-child,
    .cart:not(.checkout) .ui.table:not(.unstackable) tr > td:last-child{
        padding: 0!important;
    }
    .cart-pd .header{
        text-align: left;
    }
    .show-on-mobile{
        display: block;
    }
    .remove-item-button{
        position: absolute;
        top: 20px;
        right: 0;
    }
}

